@tailwind base;
@tailwind components;
@tailwind utilities;


html, body, #root {
  height: 100%;
  width: 100%;
  font-family: Poppins;
  background-color: #ffffff;
  overflow: hidden;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 3px !important;
  height: 8px !important;
}