.dropzone {
    text-align: center;
    padding: 20px;
    border: 3px blue dashed;
    width: 60%;
    margin: auto;
}

.btn {
    border: none;
    text-align: center;
    background-color: transparent;
    transition-duration: 0.6s;
    font-size: 1.87rem;
    /*margin-top: 0.5rem;*/
}

/*.btn:hover {*/
/*    background-color: blue;*/
/*    color: aliceblue;*/
/*}*/

.file-list {
    /* border: 3px dotted black; */
    display: flex !important;
    flex-wrap: wrap;
    width: auto;
    padding: 10px 20px;
    margin: 20px 30px;
    /* border: 3px dotted black; */
}

.file-list img {
    height: 100%;
    width: 100px;
    padding-right: 10px;
    object-fit: cover;
}